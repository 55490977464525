import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  workDescription: {
    fontFamily: 'Mulish, sans-serif',
    fontWeight: '300',
    fontSize: '16px ',
    color: '#262626',
    marginTop: '.5em',
    lineHeight: '2rem',
  },

  workTitle: {
    fontSize: '1.1em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish, sans-serif',
    fontWeight: '800',
    color: '#262626',
    marginTop: '.5em',
    width: 'fit-content',
  },

  workTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    textAlign: 'left',
    paddingRight: '15px',
    maxHeight: '15em',
    [theme.breakpoints.down('sm')]: {
      // Adjust styles for small screens
      maxHeight: 'none', // Remove maxHeight for small screens
      marginBottom: '1em', // Add some spacing at the bottom for better visual separation
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  img: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginTop: '1.5em',
    marginBottom: '1em',
    boxShadow: '0 0 10px #A0A0A0',
  },

  linkButton: {
    marginTop: '13px',
  },
}));

function WorkExperienceExamples({ workTitle, workDescription, href, action }) {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <div className={classes.imageContainer}>
          {/* <img src={workImage} alt="contractingWork" className={classes.img} /> */}
          {/* <StaticImage
            src={pace}
            alt="contractingWork"
            placeholder="blurred"
            layout="constrained"
            className={classes.img}
          /> */}
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div className={classes.workTypeContainer}>
          <Typography variant="h2" gutterBottom className={classes.workTitle}>
            {workTitle}
          </Typography>

          <Typography
            className={classes.workDescription}
            variant="body1"
            gutterBottom
          >
            {workDescription}
            <br />
            <a href={href} target="_blank" rel="noopener noreferrer">
              <Button type="primary" className={classes.linkButton}>
                {action}
                <ArrowRightOutlined />
              </Button>
            </a>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
WorkExperienceExamples.propTypes = {
  // pace: PropTypes.string.isRequired,
  workTitle: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  workDescription: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  href: PropTypes.any,
};

export default WorkExperienceExamples;
