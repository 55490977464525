import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  svgContainer: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
  },
  svg: {
    width: '100%',
    height: 'auto',
    fill: '#E9E9E9',
  },
}));

function TopDivider() {
  const classes = useStyles();

  return (
    <section>
      <Grid container>
        <Grid item xs={12} className={classes.svgContainer}>
          <div className="wrapperStyle13">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
              className={classes.svg}
            >
              <path
                d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
                className="shape-fill"
              />
            </svg>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}

export default TopDivider;
