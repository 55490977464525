import React from 'react';
import { Box } from '@material-ui/core';
import Fade from 'react-reveal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import MuiChip from '../Atoms/MuiChip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: (isSmallScreen) => (isSmallScreen ? 'column' : 'row'),
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {},
  },
  chipContainer: {
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    letterSpacing: '0.027em',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'left',
      justifyContent: 'left', // Adjust the margin top for mobile screens
    },
  },
}));

function SkillsBox() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles(isSmallScreen);

  return (
    <Box className={classes.root}>
      <Fade>
        <Box className={classes.chipContainer}>
          <MuiChip label="html" />
          <MuiChip label="css" />
          <MuiChip label="scss" />
          <MuiChip label="ES6+" />
          <MuiChip label="React" />
          <MuiChip label="React-Native" />
          <MuiChip label="TypeScript" />
          <MuiChip label="Next.js" />
        </Box>
      </Fade>
      <Fade>
        <Box className={classes.chipContainer} textAlign="center">
          <MuiChip label="Styled-Components" />
          <MuiChip label="Material-UI (MUI)" />
          <MuiChip label="Bootstrap" />
          <MuiChip label="Ant-Design" />
          <MuiChip label="Git" />
          <MuiChip label="GitHub" />
          <MuiChip label="Postman" />
          <MuiChip label="npm" />
          <MuiChip label="yarn" />
        </Box>
      </Fade>
      <Fade>
        <Box className={classes.chipContainer} textAlign="left">
          <MuiChip label="Velo(Wix)" />
          <MuiChip label="AWS" />
          <MuiChip label="Figma" />
          <MuiChip label="React-Redux" />
          <MuiChip label="Vercel" />
          <MuiChip label="React Router" />
          <MuiChip label="English-Spanish [native-bilingual]" />
        </Box>
      </Fade>
    </Box>
  );
}

export default SkillsBox;
