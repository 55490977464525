import React from 'react';
import GitHubCalendar from 'react-github-calendar';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

function ReactGhCalendar({
  username,
  colorScheme,
  fontSize,
  theme,
  blockMargin,
  blockSize,
  blockRadius,
}) {
  return (
    <div>
      <GitHubCalendar
        username={username}
        colorScheme={colorScheme}
        fontSize={fontSize}
        blockSize={blockSize}
        theme={theme}
        blockMargin={blockMargin}
        blockRadius={blockRadius}
        hideColorLegend={false}
        showWeekdayLabels
        responsive
      />
    </div>
  );
}
ReactGhCalendar.propTypes = {
  username: PropTypes.string.isRequired,
  colorScheme: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,
  blockMargin: PropTypes.number.isRequired,
  blockSize: PropTypes.number.isRequired,
  blockRadius: PropTypes.number.isRequired,
};
export default ReactGhCalendar;
