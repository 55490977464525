import React from 'react';
import Layout from '../components/Layout';
import Skills from '../components/Skills';
import Hero from '../components/Hero';
import About from '../components/About'; // Import the missing component
import TopDivider from '../components/Atoms/TopDivider';
import DayToDayWork from '../components/DayToDayWork'; // Import the missing component
import Contact from '../components/Contact'; // Import the missing component
import GitHubActivity from '../components/GitHubActivity';

function IndexPage() {
  return (
    <Layout>
      <Hero />
      <About />
      <TopDivider />
      <Skills />
      <DayToDayWork />
      <GitHubActivity />
      <Contact />
    </Layout>
  );
}

export default IndexPage;
