import React from 'react';
import Fade from 'react-reveal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import hmj from '../assets/images/hmj edit3.jpg';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  bioText: {
    fontFamily: 'Mulish, sans-serif',
    fontWeight: '200',
    fontSize: '16px',
    color: '#FFF',
    marginTop: '1.5em',
    lineHeight: '2rem',

    [theme.breakpoints.down('sm')]: {
      fontSize: '15px', // Adjust the font size for mobile screens
      lineHeight: '1.9rem', // Adjust the line height for mobile screens
      marginTop: '1em', // Adjust the margin top for mobile screens
      marginBottom: '4em', // Adjust the margin top for mobile screens
    },
  },
  bioHeader: {
    fontSize: '1.35em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish',
    letterSpacing: '0.210em',
    fontWeight: '900',
    color: '#D4EAE2',
    borderBottom: 'solid 2px #D4EAE2',
    paddingBottom: '.2em',
    width: 'fit-content',
  },
  root: {
    flexGrow: 1,
  },

  aboutInfo: {
    display: 'flex',
    fontFamily: 'Mulish',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    textAlign: 'left',
    paddingRight: '15px',
    marginRight: '15px',
    fontWeight: 300,
  },
  aboutImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    border: 'solid #D4EAE2',
    borderLeftWidth: '0px',
    borderBottomWidth: '0px',
    borderWidth: '5px',
    backgroundColor: 'transparent',
  },
  aboutSocial: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    textAlign: 'center',
    paddingRight: '25px',
    color: 'rgba(255, 255, 255, 0.5)',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '6em',
    },
  },
  img: {
    width: '100%',
    height: 'auto',
    borderStyle: 'solid',
    padding: '20px 20px 0 0',
  },
}));

function About() {
  const classes = useStyles();

  return (
    <section
      className="wrapper style8 special  "
      style={{ backgroundColor: ' #191718' }}
      id="one"
    >
      <div className="inner">
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className={classes.aboutInfo}>
                <div className={classes.aboutInfo}>
                  <Fade>
                    <Typography
                      variant="h2"
                      gutterBottom
                      className={classes.bioHeader}
                    >
                      Bio
                    </Typography>
                  </Fade>
                  <Fade>
                    <Typography
                      className={classes.bioText}
                      variant="body1"
                      gutterBottom
                    >
                      <span style={{ fontWeight: 700 }}>(TL;DR)</span> I’m a
                      self-taught Software Developer and made the transition
                      from my previous career to tech in 2021. I am consistently
                      driven to broaden my understanding of programming
                      languages and technologies, with a specific emphasis on
                      JavaScript, React, and React Native.
                      <br />
                      <br />
                      Outside of my coding endeavors, I find joy in the culinary
                      arts, experimenting with new recipes that spark my
                      creativity. I also enjoy spending time with friends and
                      family, and on occasion like to dabble in some
                      photography.
                      <br />
                    </Typography>
                  </Fade>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.aboutImage}>
                <Fade>
                  <img
                    src={hmj}
                    alt="imageOfTheAuthor"
                    className={classes.img}
                  />
                </Fade>
              </div>

              <div className={classes.aboutSocial}>
                <Fade>
                  <ul className="icons">
                    {config.socialLinks.map((social) => {
                      const { style, icon, name, url } = social;
                      return (
                        <li key={url}>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`icon ${style} ${icon}`}
                          >
                            <span className="label">{name}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </Fade>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
}

export default About;
