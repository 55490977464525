import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from 'react-reveal';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bioText: {
    fontFamily: 'Mulish, sans-serif',
    fontWeight: '600',
    fontSize: '16px',
    color: '#262626',
    lineHeight: '2rem',
    marginTop: '1.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px', // Adjust the font size for mobile screens
      lineHeight: '1.9rem', // Adjust the line height for mobile screens
    },
  },
  bioHeader: {
    fontSize: '1.35em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish',
    letterSpacing: '0.210em',
    fontWeight: '800',
    // color: '#D4EAE2',
    color: '#262626',
    borderBottom: 'solid 2px #262626',
    paddingBottom: '.5em',
    width: 'fit-content',
  },
  root: {
    flexGrow: 1,
  },

  aboutInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    textAlign: 'left',
    paddingRight: '15px',
    paddingTop: '3em',
    '@media (max-width: 768px)': {
      paddingTop: '1em',
    },
  },
  aboutImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  img: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    paddingTop: '3em',
  },
  email: {
    // color: '#D4EAE2',
    color: '#262626',
    fontSize: '18px',
    textDecoration: 'underline !important',
    fontWeight: '700',
  },
}));
const obfuscatedEmail =
  '&#109;&#111;&#114;&#101;&#108;&#046;&#104;&#101;&#114;&#110;&#097;&#110;' +
  '&#064;&#111;&#117;&#116;&#108;&#111;&#111;&#107;&#046;&#099;&#111;&#109;';
const decodedEmail = obfuscatedEmail.replace(/&#(\d+);/g, (_, match) =>
  String.fromCharCode(match)
);

function Contact() {
  const classes = useStyles();
  return (
    <section id="one" className="wrapper2 style8 special">
      <div className="inner">
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <div className={classes.aboutInfo}>
                <Fade>
                  <Typography
                    variant="h2"
                    gutterBottom
                    className={classes.bioHeader}
                  >
                    Contact
                  </Typography>
                </Fade>
                <Fade>
                  <Typography
                    className={classes.bioText}
                    variant="body1"
                    gutterBottom
                  >
                    If you are interested in developing a web application or
                    website for your business, or hiring for full-time
                    positions, please feel free to contact me. I would be happy
                    to discuss how my experience can assist you in achieving
                    your goals.
                    <br />
                    <br />
                    <Link
                      href={`mailto:${decodedEmail}`}
                      className={classes.email}
                      underline="none"
                      style={{ textDecoration: 'none' }}
                    >
                      {decodedEmail}
                    </Link>
                    <br />
                  </Typography>
                </Fade>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
}

export default Contact;
