import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from 'react-reveal';
import SkillsBox from './Molecules/SkillsBox';

const useStyles = makeStyles((theme) => ({
  skillsFonts: {
    fontFamily: 'Mulish, sans-serif',
    fontWeight: '300',
    fontSize: '16px',
    color: '#262626',
    marginTop: '.5em',
    lineHeight: '2rem',
    [theme.breakpoints.down('sm')]: {
      // height: '22em',
      fontSize: '15px', // Adjust the font size for mobile screens
      lineHeight: '1.9rem', // Adjust the line height for mobile screens
      // marginTop: '1em', // Adjust the margin top for mobile screens
    },
  },
  skillsHeader: {
    fontSize: '1.35em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish, sans-serif',
    letterSpacing: '0.210em',
    fontWeight: '900',
    color: '#262626',
    borderBottom: 'solid 2px #262626',
    paddingBottom: '.5em',
    width: 'fit-content',
  },
  root: {
    flexGrow: 1,
  },

  skillsDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    textAlign: 'left',
    paddingRight: '15px',
    marginRight: '15px',
  },
  svgContainer: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
  },
  svg: {
    width: '100%',
    height: 'auto',
    fill: '#E9E9E9',
  },
  wrapperStyle: {
    backgroundImage:
      'linear-gradient(to bottom, transparent 100%, #E9E9E9 100%)',
    paddingBottom: '0',
    marginBottom: '0',
    bottom: '0',
  },
  chipsBox: {
    [theme.breakpoints.down('sm')]: {
      height: '22em',

      marginTop: '1em', // Adjust the margin top for mobile screens
      marginBottom: '0', // Adjust the margin top for mobile screens
    },
  },
}));

function Skills() {
  const classes = useStyles();

  return (
    <section
      id="one"
      className="wrapper style8 special"
      style={{ backgroundColor: '#E9E9E9' }}
    >
      <div className="inner2">
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <div className={classes.skillsDescription}>
                <Fade>
                  <Typography
                    variant="h2"
                    gutterBottom
                    className={classes.skillsHeader}
                  >
                    Key Skills
                  </Typography>
                </Fade>
                <Fade>
                  <Typography
                    className={classes.skillsFonts}
                    variant="body1"
                    gutterBottom
                  >
                    I am a software developer with a strong specialization in
                    developing high-usage applications for both web and mobile
                    platforms. My focus is on creating solutions that enhance
                    customer engagement and deliver outstanding services. I have
                    experience in implementing industry-standard software
                    development practices to ensure the highest quality results.
                    In this fast-paced and ever-changing industry, I remain
                    dedicated to enhancing my skills and knowledge continually.
                    <br />
                    <br />
                  </Typography>
                </Fade>
              </div>

              <Fade>
                <SkillsBox />
              </Fade>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <div>
                <Fade>
                  <SkillsBox />
                </Fade>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
    </section>
  );
}

export default Skills;
