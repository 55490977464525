import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from 'react-reveal';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import md from '../assets/images/MD.png';
import bk from '../assets/images/burger-king.png';
import hd from '../assets/images/HD.png';
import jv from '../assets/images/JV.png';
import ph from '../assets/images/Pizza Hut New.png';
import tg from '../assets/images/Tgi.png';
import mobile1 from '../assets/images/mobile112.jpg';
import mobile2 from '../assets/images/mobile113.jpg';
// import mobile2 from '../assets/images/mobile app 2.png';

import { StaticImage } from 'gatsby-plugin-image';

import ExperienceModal from './Molecules/ExperienceModal';
import DarkCardTrio from './DarkCardTrio';

import WorkExperienceExamples from './Molecules/WorkExperienceExamples';

const useStyles = makeStyles((theme) => ({
  workDescription: {
    fontFamily: 'Mulish, sans-serif',
    fontWeight: '300',
    fontSize: '16px',
    color: '#262626',
    marginTop: '.5em',
    lineHeight: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px', // Adjust the font size for mobile screens
      lineHeight: '1.9rem', // Adjust the line height for mobile screens
      marginTop: '1em', // Adjust the margin top for mobile screens
      marginBottom: '0', // Adjust the margin top for mobile screens
    },
  },
  workTypeHeader: {
    fontSize: '1.35em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish, sans-serif',
    letterSpacing: '0.210em',
    fontWeight: '900',
    color: '#262626',
    borderBottom: 'solid 2px #262626',
    paddingBottom: '.5em',
    width: 'fit-content',
  },
  icWorkHeader: {
    fontSize: '1.35em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish, sans-serif',
    letterSpacing: '0.210em',
    fontWeight: '900',
    color: '#262626',
    borderBottom: 'solid 2px #262626',
    paddingBottom: '.5em',
    width: 'fit-content',
    marginBottom: '20px',
  },
  monchisTitle: {
    fontSize: '1.35em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish, sans-serif',
    fontWeight: '900',
    color: '#262626',
    marginTop: '.5em',
    width: 'fit-content',
  },

  root: {
    flexGrow: 1,
  },

  workTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    textAlign: 'left',
    paddingRight: '15px',
  },
  workTypeContainer1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    textAlign: 'left',
    paddingRight: '15px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      alignItems: 'left',
      textAlign: 'left',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  img: {
    width: '90%',
    height: 'auto',
    borderRadius: '8px',
    marginTop: '1.5em',
    marginBottom: '1em',
    boxShadow: '0 0 10px #bbb',

    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)', // A more subtle scale effect
      // boxShadow: '0 0 20px #F1214B',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  img1: {
    width: '90%',
    height: 'auto',
    borderRadius: '8px',
    marginTop: '1.5em',
    marginBottom: '1em',
    boxShadow: '0 0 10px #bbb',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  email: {
    color: '#D4EAE2',
    borderBottom: 'solid .5px #55666B',
    fontSize: '20px',
    textDecoration: 'none',
    fontWeight: '700',
  },
  svgContainer: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
  },
  svg: {
    fill: '#E9E9E9',
    width: '100%',
    lineHeight: '0',
    height: '6em',
    transform: 'rotate(180deg)',
  },
}));

function DayToDayWork() {
  const [open, setOpen] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [active, setActive] = useState(true);
  const classes = useStyles();

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setActive(false);
      setImageLoading(false);
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(true);
    setImageLoading(true);
    setActiveKeys([]);
  };

  // const jeanDavisDescription =
  //   'The family of Jean Davis commissioned a gallery collection featuring a landing page. The website is currently a work in progress, as most of the paintings are in private collections and being retrieved. It was built using Next JS and Tailwind CSS.';
  const pillDrillDescription =
    "I undertook a contractual position involving the revamp and migration of a WordPress website to a React/Gatsby frontend. Collaborating with a team of two other developers, our primary focus was to implement an in-site ordering system and ensure responsive design, as per the client's priorities.";
  // const nwSnowDogDescription =
  //   "NW Snowdog Rescue's non-profit rescue operation required an updated website to connect with people interested in fostering or adopting rescued Huskies. The site was built with a modular Wix CMS, and React for easy maintenance. ";
  const nwSnowDogDescription =
    " NW Snowdog Rescue's non-profit rescue operation required an updated website to connect with people interested in fostering or adopting rescued Huskies. The site was built with a modular Wix CMS, and React for easy maintenance, ensuring communication and accessibility for potential adopters and foster families.";

  return (
    <>
      <section
        id="one"
        className="wrapper style8 special"
        style={{ backgroundColor: '#E9E9E9' }}
      >
        <div className="inner2">
          <div className={classes.root}>
            <div>
              <Grid item xs={12} sm={12}>
                <div className={classes.workTypeContainer1}>
                  <Fade>
                    <Typography
                      variant="h2"
                      gutterBottom
                      className={classes.icWorkHeader}
                    >
                      Most Recent Contract Work
                    </Typography>
                  </Fade>
                  <Fade>
                    <Typography
                      className={classes.workDescription}
                      variant="body1"
                      gutterBottom
                      style={{ marginTop: '1em', textAlign: 'left' }}
                    >
                      As an independent contractor, I&lsquo;ve partnered with
                      clients based in California, Washington State, and
                      Washington D.C. to conceptualize, create, and launch web
                      and mobile applications, including several for non-profit
                      organizations. This entailed efficiently managing various
                      responsibilities to ensure timely delivery in alignment
                      with organizational timelines.
                    </Typography>
                    <div>
                      <DarkCardTrio />
                    </div>
                  </Fade>
                </div>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Fade>
                    <div className={classes.imageContainer}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.nwsnowdogrescue.org/"
                      >
                        <StaticImage
                          src="../assets/images/nwSnowDog.png"
                          alt="contractingWork"
                          placeholder="blurred"
                          layout="constrained"
                          className={classes.img}
                        />
                      </a>
                    </div>
                    <WorkExperienceExamples
                      workTitle="NW Snowdog Rescue - Washington State"
                      workDescription={nwSnowDogDescription}
                      href="https://www.nwsnowdogrescue.org/"
                      action="VISIT"
                    />
                  </Fade>
                </Grid>
                <br />
                <br />
                <Grid item xs={12} sm={6}>
                  <Fade>
                    <div className={classes.imageContainer}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.pilldrill.com/"
                      >
                        <StaticImage
                          src="../assets/images/pillDrill2.png"
                          alt="contractingWork"
                          placeholder="blurred"
                          layout="constrained"
                          className={classes.img}
                        />
                      </a>
                    </div>
                    <WorkExperienceExamples
                      workTitle="PillDrill - California"
                      workDescription={pillDrillDescription}
                      href="https://www.pilldrill.com/"
                      action="VISIT"
                    />
                  </Fade>
                </Grid>
              </Grid>
              <br />
              <br />
              <br />
              <br />
            </div>
            <Grid item xs={12} sm={12}>
              <div className={classes.workTypeContainer}>
                <Fade>
                  <Typography
                    variant="h2"
                    gutterBottom
                    className={classes.workTypeHeader}
                  >
                    Prior Experience
                  </Typography>
                </Fade>
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <div className={classes.imageContainer}>
                  <Fade>
                    <a
                      href="https://monchis.com.py/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        src="../assets/images/monchisMain.png"
                        alt="image of the workplace"
                        placeholder="blurred"
                        layout="constrained"
                        className={classes.img}
                      />
                    </a>
                  </Fade>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classes.workTypeContainer}>
                  <Fade>
                    <Typography
                      variant="h2"
                      gutterBottom
                      className={classes.monchisTitle}
                    >
                      Monchis
                    </Typography>
                  </Fade>
                  <Fade>
                    <Typography
                      className={classes.workDescription}
                      variant="body1"
                      gutterBottom
                    >
                      Between April 2022 and February 2024, I held the role of
                      Front-end and Mobile Developer at{' '}
                      <a
                        href="https://monchis.com.py/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                        }}
                      >
                        Monchis
                      </a>{' '}
                      - a delivery and logistics firm akin to DoorDash and
                      Postmates, that links merchants and consumers through its
                      platform, in Asunción, Paraguay. It holds the top position
                      among delivery apps in the country and has formed
                      partnerships with global corporations like{' '}
                      <span style={{ fontWeight: 'bold' }}>McDonald's</span>,{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Restaurant Brands International (Burger King)
                      </span>
                      , and{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Yum! Brands (Pizza Hut, KFC etc.)
                      </span>
                      . My primary responsibilities were to develop features for
                      customer-facing mobile app utilizing React Native, along
                      with the in-house ERP and CRM applications, which run on
                      desktop platforms and are mainly used for managing the
                      company&lsquo;s accounting, procurement, and logistics
                      operations.
                      <br />
                      <br />
                      <Button type="primary" onClick={showModal}>
                        READ MY EXPERIENCE
                        <ArrowRightOutlined />
                      </Button>
                    </Typography>
                  </Fade>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <ExperienceModal
        open={open}
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        onCancel={handleClose}
        src1={md}
        src2={tg}
        src3={hd}
        src4={jv}
        src5={ph}
        src6={bk}
        // src7={mobile2}
        // src8={mobile1}
        // srcPhone={monchisApp}
        imageLoading={imageLoading}
        setImageLoading={showModal}
        active={active}
      />
    </>
  );
}

export default DayToDayWork;
