import React from 'react';
import styled from 'styled-components';
import DarkCard from './Atoms/DarkCard';
import design from '../assets/images/designBlue.png';
import develop from '../assets/images/developBlue.png';
import launch from '../assets/images/launchBlue.png';

const Container = styled.div`
  margin-bottom: 1em;
  padding: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mulish';
  cursor: 'none';

  /* Responsive styles */
  @media (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 50px;
    height: 59em;
    justify-content: 'left';
    align-items:'left',
    text-align: 'left',
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  \
  /* Responsive styles */
  @media (max-width: 768px) {
    flex-direction: column; /* Stack the cards vertically */
  }
`;

function DarkCardTrio() {
  return (
    <Container>
      <CardContainer>
        <DarkCard
          imgSrc={design}
          title="Design"
          text="During the design phase, I collaborate with the client to envision the software's intended function, characteristics, and overall user interface."
        />
        <DarkCard
          imgSrc={develop}
          title="Develop"
          text="Throughout the development phase, the software is constructed, verified, and enhanced to fulfill the specified requirements."
        />
        <DarkCard
          imgSrc={launch}
          title="Deploy"
          text="In the deployment phase, the software is distributed to users and its dependability is verified to ensure proper functioning."
        />
      </CardContainer>
    </Container>
  );
}

export default DarkCardTrio;
