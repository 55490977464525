import React from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

const Element = (props) => {
  return props.children;
};

class Scroll extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    element: PropTypes.string,
    offset: PropTypes.number,
    timeout: PropTypes.number,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // Check if the window object is defined before calling smoothscroll.polyfill()
    if (typeof window !== 'undefined') {
      smoothscroll.polyfill();
    }
  }

  handleClick(e) {
    e.preventDefault();
    let elem = null;
    let scroll = true;
    const { type, element, offset, timeout } = this.props;

    if (typeof window === 'undefined') {
      return;
    }

    if (type && element) {
      switch (type) {
        case 'class':
          elem = document.getElementsByClassName(element)[0];
          scroll = elem ? true : false;
          break;
        case 'id':
          elem = document.getElementById(element);
          scroll = elem ? true : false;
          break;
        default:
          break;
      }
    }

    if (scroll) {
      this.scrollTo(elem, offset, timeout);
    } else {
      console.log(`Element not found: ${element}`); // eslint-disable-line no-console
    }
  }

  scrollTo(element, offSet = 0, timeout = null) {
    const elemPos = element
      ? element.getBoundingClientRect().top + window.pageYOffset
      : 0;

    if (timeout) {
      setTimeout(() => {
        // Check if the window object is defined before calling window.scroll()
        if (typeof window !== 'undefined') {
          window.scroll({ top: elemPos + offSet, left: 0, behavior: 'smooth' });
        }
      }, timeout);
    } else {
      // Check if the window object is defined before calling window.scroll()
      if (typeof window !== 'undefined') {
        window.scroll({ top: elemPos + offSet, left: 0, behavior: 'smooth' });
      }
    }
  }

  render() {
    return (
      <Element>
        {typeof this.props.children === 'object' ? (
          React.cloneElement(this.props.children, { onClick: this.handleClick })
        ) : (
          <span onClick={this.handleClick}>{this.props.children}</span>
        )}
      </Element>
    );
  }
}

export default Scroll;
