/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from 'react-reveal';
import { useMediaQuery } from 'react-responsive';
import ReactGhCalendar from './Atoms/ReactGhCalendar';

const useStyles = makeStyles(() => ({
  gitHubContributionsTitle: {
    fontSize: '1.35em',
    lineHeight: '1.75em',
    fontFamily: 'Mulish',
    letterSpacing: '0.210em',
    fontWeight: '900',

    color: '#262626',

    borderBottom: 'solid 2px #262626',
    paddingBottom: '.5em',
    width: 'fit-content',
  },

  root: {
    flexGrow: 1,
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    textAlign: 'left',
    paddingRight: '15px',
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '50px',
    paddingTop: '3em',
    paddingBottom: '2em',
    fontFamily: 'Mulish, sans-serif !important',
    borderColor: '#262626',

    // Responsive styles
    '@media (max-width: 768px)': {
      marginTop: '60px',
      paddingTop: '2em',
      marginRight: '2em',
    },
  },
}));

const explicitTheme = {
  dark: ['#c4c5c7', '#39d353', '#27af3e', '#1d842f', '#216E39'],
};

function GitHubActivity() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  // const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;
  const classes = useStyles();

  return (
    <section
      id="one"
      className="wrapper style8 special"
      style={{
        backgroundColor: '#E9E9E9',
        paddingTop: '110px',
        fontWeight: 600,
        color: '#262626',
      }}
    >
      <div className="inner2">
        <div className={classes.root}>
          <Grid item xs={12} sm={12}>
            <div className={classes.titleContainer}>
              <Fade>
                <Typography
                  variant="h2"
                  gutterBottom
                  className={classes.gitHubContributionsTitle}
                >
                  GitHub Contributions
                </Typography>
              </Fade>
            </div>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <div className={classes.calendarContainer}>
                <Fade>
                  <a
                    href="https://github.com/HernanMorel"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {typeof window !== 'undefined' && (
                      <ReactGhCalendar
                        username="HernanMorel"
                        colorScheme="dark"
                        fontSize={isMobile ? 10 : 14}
                        theme={explicitTheme}
                        blockMargin={isMobile ? 2 : 4}
                        blockSize={isMobile ? 4 : 12}
                        blockRadius={2}
                      />
                    )}
                  </a>
                </Fade>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
}

export default GitHubActivity;
