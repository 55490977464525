import React from 'react';
import Fade from 'react-reveal';
import TypingEffect from './Atoms/TypingEffect';

// eslint-disable-next-line import/no-named-as-default-member
import Scroll from './Scroll';

function Hero() {
  const config = {
    heading: 'Hernán/m.',
  };

  return (
    <Fade>
      <section id="banner" style={{ backgroundColor: '#191718' }}>
        <div className="inner">
          <Fade>
            <h2>{config.heading}</h2>
          </Fade>
          <Fade>
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TypingEffect />
            </p>
          </Fade>
        </div>
        {/* <Fade>
          <Scroll type="id" element="one">
            <a href="#one" className="more">
              Learn More
            </a>
          </Scroll>
        </Fade> */}
      </section>
    </Fade>
  );
}

export default Hero;
