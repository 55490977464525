import React from 'react';
import { TypeAnimation } from 'react-type-animation';

function TypingEffect() {
  return (
    <TypeAnimation
      sequence={[
        2100,
        'Forward Thinker',
        1000,
        'Solution-Driven',
        2000,
        'Software Developer',
        3000,
        'Culinary Enthusiast',
        4000,

        () => {},
      ]}
      wrapper="span"
      cursor
      repeat={Infinity}
      className="typingEffect"
    />
  );
}
export default TypingEffect;
