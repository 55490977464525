import React from 'react';
import {
  Col,
  Collapse,
  Divider,
  Image,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import Paragraph from 'antd/es/typography/Paragraph';
import Chip from '@material-ui/core/Chip';
import { useMediaQuery } from 'react-responsive';

const { Title } = Typography;
const { Panel } = Collapse;

const chipStyle = {
  backgroundColor: '#A1C2BF',
  color: '#262626 ',
  boxShadow: '0 0 10px #CFCFCF ',
  fontWeight: '600',
  fontSize: '13px',
  fontFamily: 'Mulish, sans-serif',
  padding: 0,
  cursor: 'none',
};

const titleDescriptionStyles = {
  fontWeight: '600',
  lineHeight: '2.25rem',
  paddingLeft: '0 !important',
  fontFamily: 'Mulish, sans-serif',
  letterSpacing: '0 !important',
};
const descriptionParagraphStyle = {
  fontWeight: '300',
  lineHeight: '2.25rem',
  fontFamily: 'Mulish, sans-serif',
  paddingLeft: '0',
  color: '#262626',
};

const imageContainer = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  justifyItems: 'center',
};

// Media queries for responsive styles
const mediaQueries = {
  '@media (max-width: 844px)': {
    chipStyle: {
      fontSize: '12px',
    },
    titleDescriptionStyles: {
      lineHeight: '2rem',
    },
    descriptionParagraphStyle: {
      lineHeight: '2rem',
    },
    imageContainer: {
      height: '100%',
      marginRight: '40px',
      alignItems: 'center',
    },
  },
};

// Merge the styles with media queries
const responsiveStyles = {
  chipStyle: {
    ...chipStyle,
    ...mediaQueries['@media (max-width: 844px)'].chipStyle, // Update the max-width to 844px
  },
  titleDescriptionStyles: {
    ...titleDescriptionStyles,
    ...mediaQueries['@media (max-width: 844px)'].titleDescriptionStyles, // Update the max-width to 844px
  },
  descriptionParagraphStyle: {
    ...descriptionParagraphStyle,
    ...mediaQueries['@media (max-width: 844px)'].descriptionParagraphStyle, // Update the max-width to 844px
  },
  imageContainer: {
    ...imageContainer,
    ...mediaQueries['@media (max-width: 844px)'].imageContainer, // Update the max-width to 843px
  },
};

function ExperienceModal({
  open,
  onCancel,
  activeKeys,
  setActiveKeys,
  src1,
  src2,
  src3,
  src4,
  src5,
  src6,
  // src7,
  // src8,
  imageLoading,
  setImageLoading,
  active,
}) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <Modal
      title="MONCHIS"
      open={open}
      onCancel={onCancel}
      width={1000}
      bodyStyle={{
        fontFamily: 'Mulish, sans-serif',
        padding: isMobile ? '20px 5px' : '20px 35px',
        height: '46em',
        maxHeight: '50em',
        overflow: 'auto',
        overflowX: 'hidden',
      }}
      footer={null}
      centered
      destroyOnClose
      closeIcon={<CloseOutlined />}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Row>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 7, order: 1 }}>
          <Title level={5}>Tech-Stack</Title>
          <br />
          <div style={{ fontSize: '14px !important' }}>
            <Paragraph>
              <Chip label="ES6+" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Paragraph>
                <Chip label="TypeScript" style={responsiveStyles.chipStyle} />
              </Paragraph>
              <Chip label="React" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="React-Native" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="Redux" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="Context" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="Git" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="GitHub" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="Ant-Design" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="CSS-Modules" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="Figma" style={responsiveStyles.chipStyle} />
            </Paragraph>
            <Paragraph>
              <Chip label="AWS" style={responsiveStyles.chipStyle} />
            </Paragraph>
          </div>
        </Col>

        <Col
          xs={{ span: 24, order: 1 }}
          lg={{ span: 17, order: 2 }}
          className="imageColClassName"
        >
          {!isMobile && (
            <Spin size="middle" centered spinning={active || imageLoading}>
              <Title level={5} style={{ marginBottom: 0 }}>
                Products and Partnerships
              </Title>
              <br />
              <div style={responsiveStyles.imageContainer}>
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 80 : 140}
                  src={src1}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '6em',
                  }}
                />
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 100 : 140}
                  src={src2}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '6em',
                  }}
                />
              </div>
              <div style={responsiveStyles.imageContainer}>
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 80 : 140}
                  src={src3}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '6em',
                  }}
                />
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 80 : 140}
                  src={src4}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '6em',
                  }}
                />
              </div>
              <div style={responsiveStyles.imageContainer}>
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 80 : 140}
                  src={src5}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '6em',
                  }}
                />
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 80 : 140}
                  src={src6}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '6em',
                  }}
                />
              </div>
            </Spin>
          )}
        </Col>
      </Row>

      <br />
      <Row>
        <Title level={5}>Mobile App & Logistic Dashboards</Title>
        <Col span={24} />
        <Collapse
          ghost
          destroyInactivePanel
          activeKey={activeKeys}
          onChange={setActiveKeys}
          size="small"
        >
          <Panel header="My Notable Contributions" key="1">
            <Paragraph style={responsiveStyles.titleDescriptionStyles}>
              Mobile App Development
            </Paragraph>
            {/* To be added later */}
            {/* <Row
              style={{
                justifyContent: 'space-between',
                marginLeft: '80px',
                marginRight: '80px',
              }}
            >
              <Col>
                <Paragraph style={responsiveStyles.titleDescriptionStyles}>
                  Mobile App Home Screen
                </Paragraph>
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 80 : 300}
                  src={src7}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '2em',
                  }}
                />
              </Col>
              <Col>
                <Paragraph style={responsiveStyles.titleDescriptionStyles}>
                  Business Profile Screen
                </Paragraph>
                <Image
                  preview={false}
                  alt="monchisDrivers"
                  width={isMobile ? 80 : 300}
                  src={src8}
                  onLoad={() => setImageLoading(false)}
                  style={{
                    marginBottom: isMobile ? '3em' : '2em',
                  }}
                />
              </Col>
            </Row> */}

            <Paragraph style={descriptionParagraphStyle}>
              During my tenure, I spearheaded the development of a
              cross-platform mobile application for both iOS and Android,
              leveraging React-Native. This project showcased a meticulously
              crafted atomic component structure, diligently applied throughout
              all facets of the application. The overarching goal was to ensure
              consistency, scalability, and a superior user experience.
              <br />
              <br />I also led the transformation of more than 30 user-interface
              screens for the app's fifth iteration. This involved the
              introduction of redesigned components, the infusion of fresh color
              palettes, and the meticulous selection of updated fonts. These
              visual enhancements not only provided a modern and visually
              appealing aesthetic but also contributed to a more engaging and
              user-friendly application interface.
            </Paragraph>
            <Divider />
            <Paragraph style={responsiveStyles.titleDescriptionStyles}>
              Propietary ERP and CRM Development
            </Paragraph>
            <Paragraph style={descriptionParagraphStyle}>
              I led development efforts contributing to 36 version releases
              spanning seven major projects, integrations, and ongoing
              maintenance for the ERP and CRM systems. Among those, was a
              collaborative project with the development team to upgrade the
              primary payment module for 500+ drivers within the network. This
              involved recreating layouts to facilitate CRUD (Create, Read,
              Update, Delete) operations via RESTful APIs, resulting in a 40%
              productivity boost.
              <br />
              <br />
              We employed Agile methodology and adhered to SDLC best practices
              when reconstructing the modules from the ground up. Our approach
              was aligned with industry-standard Software Development Life Cycle
              (SDLC) practices, incorporating Agile methodologies using Scrum
              and Jira.
            </Paragraph>
            <Divider />
          </Panel>
          <Panel header="Problems and Lessons Learned" key="2">
            <Paragraph style={responsiveStyles.descriptionParagraphStyle}>
              During my tenure at Monchis, I undertook a transformative
              initiative within the logistics department, optimizing the
              existing ERP and CRM systems. I executed this with a multi-pronged
              approach that included modular refactoring, the implementation of
              Atomic Design principles for component architecture, the
              incorporation of Custom Hooks for streamlined functionality, and
              the elimination of redundant inline styles. As a result of these
              strategic enhancements, the system's performance and
              maintainability were substantially elevated.
              <br />
              <br />
              One of the quantifiable impacts of this effort was a remarkable
              40% decrease in load times for critical API calls, directly
              contributing to a smoother and more responsive user experience.
              This achievement not only streamlined operations but also
              heightened overall user satisfaction.
            </Paragraph>
          </Panel>
        </Collapse>
      </Row>
    </Modal>
  );
}
ExperienceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  activeKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActiveKeys: PropTypes.func.isRequired,
  src1: PropTypes.string.isRequired,
  src2: PropTypes.string.isRequired,
  src3: PropTypes.string.isRequired,
  src4: PropTypes.string.isRequired,
  src5: PropTypes.string.isRequired,
  src6: PropTypes.string.isRequired,
  // src7: PropTypes.string.isRequired,
  // src8: PropTypes.string.isRequired,
  imageLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setImageLoading: PropTypes.any.isRequired,
  active: PropTypes.bool.isRequired,
};

export default ExperienceModal;
