import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

function MuiChip({ label }) {
  return (
    <Chip
      label={label}
      style={{
        backgroundColor: '#A1C2BF',
        color: ' #262626 ',
        fontWeight: '600',
        fontSize: '13px',
        fontFamily: 'Mulish, sans-serif',
        padding: 0,
      }}
    />
  );
}

MuiChip.propTypes = {
  label: PropTypes.string.isRequired,
};
export default MuiChip;
