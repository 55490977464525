import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Card = styled.div`
  position: relative;
`;

const CardFace = styled.div`
  width: 300px;
  height: 200px;
  transition: 0.5s;

  /* Responsive styles */
  @media (max-width: 768px) {
    height: 150px;
  }
`;

const CardFace1 = styled(CardFace)`
  position: relative;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);

  ${Card}:hover & {
    background: transparent;
    transform: translateY(0);
  }
`;

const CardFace1Content = styled.div`
  opacity: 1;
  transition: 0.5s;

  ${Card}:hover & {
    opacity: 1;
  }
`;

const CardFace1ContentImg = styled.img`
  max-width: 100px;
  color: red;
`;

const CardFace1ContentTitle = styled.span`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-family: 'Mulish';
  margin: 10px 0 0;
  padding: 0;
  color: #262626;
  text-align: center;
  font-size: 1.2em;
  letter-spacing: 0.2em;
  font-weight: 900;
`;

const CardFace2 = styled(CardFace)`
  position: relative;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: none;
  box-sizing: border-box;
  transform: translateY(-100px);
  opacity: 0;
  ${Card}:hover & {
    transform: translateY(0);
    opacity: 1;
    background: transparent;
  }
  /* Responsive styles */
  @media (max-width: 768px) {
    padding: 50px 20px 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const CardFace2Content = styled.div`
  margin: 0;
  padding: 0;
  color: #262626;
  font-size: 17.3333px;
  font-family: 'Mulish';
  letter-spacing: 0;
  font-weight: 300;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

function DarkCard({ imgSrc, title, text }) {
  return (
    <Card>
      <CardFace1>
        <CardFace1Content>
          <CardFace1ContentImg src={imgSrc} alt={title} />
          <CardFace1ContentTitle>{title}</CardFace1ContentTitle>
        </CardFace1Content>
      </CardFace1>
      <CardFace2>
        <CardFace2Content>
          <div style={{ margin: '0' }}>
            <span>{text}</span>
          </div>
        </CardFace2Content>
      </CardFace2>
    </Card>
  );
}

DarkCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default DarkCard;
